<template>
  <b-container
    fluid
  >
    <b-row class="justify-content-md-center">
      <b-col md="12">

        <b-overlay
          :show="offreProcess"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-lg
          rounded="sm"
        >
          <b-card class="mb-0 my-5">
            <form-wizard
              color="#7367F0"
              title="Créer un fret"
              subtitle="Rejoignez nous pour profitez des opportunités que vous offre la plateforme"
              finish-button-text="Soumettre"
              back-button-text="Précédent"
              back-button-size="sm"
              finish-button-size="sm"
              next-button-size="sm"
              next-button-text="Suivant"
              class="steps-transparent mb-3 font-size"
              justified
              @on-complete="storeOffre()"
            >

              <!-- account detail tab -->
              <tab-content :before-change="ValidationTrajetForm">
                <validation-observer
                  ref="trajetRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations sur le traget
                      </h5>
                      <small class="text-muted" />
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Information sur départ de la marchandise"
                        class="text-primary h6"
                      />

                      <b-form-group
                        label="Pays"
                        label-for="country_depart"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="pays"
                          rules="required"
                        >
                          <v-select
                            id="country_depart"
                            v-model="form.country_depart"
                            label="country_name"
                            :state="errors.length > 0 ? false : null"
                            :options="countries"
                            placeholder="Choisir un pays"
                            :reduce="(countries) => countries.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="Département"
                        label-for="departement_depart"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="département"
                          rules="required"
                        >
                          <v-select
                            id="departement_depart"
                            v-model="form.departement_depart"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentDepartements"
                            placeholder="Choisir un département"
                            :reduce="(currentDepartements) => currentDepartements.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Ville"
                        label-for="ville_depart"
                      >

                        <validation-provider
                          #default="{ errors }"
                          name="ville"
                          rules="required"
                        >
                          <v-select
                            id="ville_depart"
                            v-model="form.ville_depart_id"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentVilles"
                            placeholder="Choisir une ville"
                            :reduce="(currentVilles) => currentVilles.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-row>
                        <b-col md="12">
                          <!-- basic -->
                          <b-form-group>
                            <label
                              class="text-primary h6"
                            >Ajouter une localisation
                            </label>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-danger"
                              class="float-right"
                              size="sm"
                              pill
                              @click="clearLocation('depart')"
                            >
                              Annuler
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-modal.modal.localisation-depart
                              variant="outline-primary"
                              class="float-right mr-1"
                              size="sm"
                              pill
                            >
                              Ajouter
                            </b-button>
                            <!-- basic -->

                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                        >
                          <!-- basic -->
                          <b-form-group
                            label="Emplacement Départ"
                            label-for="emplacementDepart"
                          >
                            <b-form-input
                              id="emplacementDepart"
                              v-model="form.addressDepart"
                              size="sm"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                          xl="6"
                          class="mb-1"
                        >
                          <!-- basic -->
                          <b-form-group
                            label="Longitude Départ"
                            label-for="longitudeDepart"
                          >
                            <b-form-input
                              id="longitudeDepart"
                              v-model="form.longitudeDepart"
                              size="sm"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                          xl="6"
                          class="mb-1"
                        >

                          <!-- basic -->
                          <b-form-group
                            label="Latitude Départ"
                            label-for="latitudeDepart"
                          >
                            <b-form-input
                              id="latitudeDepart"
                              v-model="form.latitudeDepart"

                              size="sm"
                              readonly
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                    <hr>
                    <b-col md="6">

                      <b-form-group
                        class="text-primary h6"
                        label="Information sur l'arrivée de la marchandise"
                      />
                      <b-form-group
                        label="Pays"
                        label-for="country_arrive"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="pays"
                          rules="required"
                        >
                          <v-select
                            id="country_arrive"
                            v-model="form.country_arrive"
                            label="country_name"
                            :state="errors.length > 0 ? false : null"
                            :options="countries"
                            placeholder="Choisir un pays"
                            :reduce="(countries) => countries.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Département"
                        label-for="departement_arrive"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="département"
                          rules="required"
                        >
                          <v-select
                            id="departement_arrive"
                            v-model="form.departement_arrive"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentDepartements"
                            placeholder="Choisir un département"
                            :reduce="(currentDepartements) => currentDepartements.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Ville"
                        label-for="ville_arrive"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="ville d'arrivée"
                          rules="required"
                        >
                          <v-select
                            id="ville_arrive"
                            v-model="form.ville_destination_id"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentVillesArrive"
                            placeholder="Choisir une ville"
                            :reduce="(currentVilles) => currentVilles.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-row>
                        <b-col md="12">
                          <!-- basic -->
                          <b-form-group>
                            <label
                              class="text-primary h6"
                            >Ajouter une localisation destination
                            </label>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-danger"
                              class="float-right "
                              size="sm"
                              pill
                              @click="clearLocation('destination')"
                            >
                              Annuler
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-modal.modal.localisation-destination
                              variant="outline-primary"
                              class="float-right mr-1"
                              size="sm"
                              pill
                            >
                              Ajouter
                            </b-button>
                            <!-- basic -->

                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                        >
                          <!-- basic -->
                          <b-form-group
                            label="Emplacement Destination"
                            label-for="emplacementDestination"
                          >
                            <b-form-input
                              id="emplacementDestination"
                              v-model="form.addressDestination"
                              size="sm"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                          xl="6"
                          class="mb-1"
                        >
                          <!-- basic -->
                          <b-form-group
                            label="Longitude Destination"
                            label-for="longitudeDestination"
                          >
                            <b-form-input
                              id="longitudeDestination"
                              v-model="form.longitudeDestination"
                              size="sm"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                          xl="6"
                          class="mb-1"
                        >

                          <!-- basic -->
                          <b-form-group
                            label="Latitude Destination"
                            label-for="latitudeDestination"
                          >
                            <b-form-input
                              id="latitudeDestination"
                              v-model="form.latitudeDestination"

                              size="sm"
                              readonly
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>

                  </b-row>
                </validation-observer>
              </tab-content>

              <!-- profil detail tab -->
              <tab-content :before-change="validationMarchandise">
                <validation-observer
                  ref="marchandiseRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations sur l'Objet / Marchandises
                      </h5>
                      <small class="text-muted" />
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Catégorie"
                        label-for="categorie_marchandise_slug"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="catégorie"
                          rules="required"
                        >
                          <v-select
                            id="categorie_marchandise_slug"
                            v-model="form.categorie_marchandise_slug"
                            label="libelle"
                            :state="errors.length > 0 ? false : null"
                            :options="categoryMarchandises"
                            placeholder="Choisir une catégorie de marchandise"
                            :reduce="(categoryMarchandises) => categoryMarchandises.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="Longueur"
                        label-for="longueur"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="longueur"
                          rules="required"
                        >
                          <b-input-group append="mètre">
                            <b-form-input
                              v-model="form.longueur"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Longueur"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">

                      <b-form-group
                        label="Type de marchandise"
                        label-for="marchandise_slug"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="type marchandise"
                          rules="required"
                        >
                          <v-select
                            id="marchandise_slug"
                            v-model="form.marchandise_slug"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentType"
                            placeholder="Choisir un type de marchandise"
                            :reduce="(currentType) => currentType.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Volume"
                        label-for="volume"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="volume"
                          rules="required"
                        >
                          <b-input-group append="m³">
                            <b-form-input
                              v-model="form.volume"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Volume"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="8">
                          <b-form-group
                            label="Poids"
                            label-for="poids_marchandise"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="poids_marchandise"
                              rules="required"
                            >
                              <b-form-input
                                v-model="form.poids_marchandise"
                                size="sm"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Poids"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            label="Unité de mesure"
                            label-for="unite_mesure_id"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="unité de mesure"
                              rules="required"
                            >
                              <v-select
                                id="unite_mesure_id"
                                v-model="form.unite_mesure_id"
                                label="libelle"
                                :state="errors.length > 0 ? false : null"
                                :options="mesures"
                                placeholder="Mesure"
                                :reduce="(mesures) => mesures.id"
                                class="select-size-sm"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="fichier"
                        label="Pièce jointe"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="pièce jointe"
                        >
                          <b-form-file
                            id="fichier"
                            accept="image/*"
                            size="sm"
                            placeholder="Choisir un fichier"
                            @change="handleImageSelected"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- account detail tab -->
              <tab-content :before-change="validationConditionForm">
                <validation-observer
                  ref="conditionRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations sur les conditions
                      </h5>
                      <small class="text-muted" />
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="date_prev_depart"
                        label-size="lg"
                      >
                        <span>Date et heure de chargement</span>
                        <validation-provider
                          #default="{ errors }"
                          name="date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="form.date_prev_depart"
                            class="form-control"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Date départ"
                            :config="config"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="date_prev_livraison"
                        label-size="lg"
                      >
                        <span>Date et heure de livraison</span>
                        <validation-provider
                          #default="{ errors }"
                          name="date livraison"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="form.date_prev_livraison"
                            class="form-control"
                            size="sm"
                            placeholder="Date livraison"
                            :state="errors.length > 0 ? false : null"
                            :config="config"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <label
                        for="textarea-small"
                        class="text-nowrap"
                      >Commentaire:</label>
                    </b-col>
                    <b-col
                      sm="12"
                      class="mb-1"
                    >
                      <b-form-textarea
                        id="textarea-small"
                        v-model="form.commentaire"
                        size="sm"
                        placeholder="Commentaire"
                      />
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right mt-2"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>

                  </b-row>
                </validation-observer>
              </tab-content>

              <!-- account detail tab -->
              <tab-content :before-change="validationContactForm">
                <validation-observer
                  ref="contactRules"
                  tag="form"
                >
                  <b-row class="justify-content-md-center">
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations du contact immédiat
                      </h5>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Nom"
                        label-for="nom_contact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="nom"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.nom_contact"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        label-for="email_contact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            v-model="form.email_contact"
                            size="sm"
                            type="email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Téléphone"
                        label-for="telephone_contact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="téléphone"
                          rules="required"
                        >
                          <VuePhoneNumberInput
                            v-model="form.telephonefull"
                            default-country-code="BJ"
                            show-code-on-list
                            size="sm"
                            :translations="configPhoneNumber"
                            @update="telephonefull = $event"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Partagez avec votre réseau de transporteurs"
                        label-for="diffusion"
                      >
                        <validation-provider
                          name="diffusion"
                        >
                          <b-form-checkbox
                            v-model="form.diffusion"
                          >
                            {{ form.diffusion? 'Oui':'Non' }}
                          </b-form-checkbox>
                        </validation-provider>
                      </b-form-group>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

            </form-wizard>
          </b-card>
        </b-overlay>
        <b-modal
          id="localisation-depart"
          ref="my-modal-depart"
          ok-title="Fermer"
          ok-only
          button-size="sm"
          centered
          modal-class="modal-primary"
          size="lg"
          no-close-on-backdrop
          title="Ajouter une géolocalisation"
          @show="showModal('depart')"
        >
          <b-card
            title="Géolocalisation"
            style="height: 39rem !important;"
          >
            <l-map
              ref="map"
              :zoom="zoom"
              :center="userLocationDepart"
              :options="mapOptions"
              style="width:100%; height: 445px"
              @click="onMapClickDepart"
              @ready="doSomethingOnReady()"
            >
              <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"
              />
              <l-circle
                :lat-lng.sync="userLocationDepart"
                :radius="circle.radius"
                :color="circle.color"
              />
              <l-control-attribution
                :position="attributionPosition"
                :prefix="attributionPrefix"
              />
              <l-geosearch :options="geoSearchOptions" />

              <l-marker
                :lat-lng.sync="userLocationDepart"
                visible
                :icon="iconT"
                draggable
                @dragstart="dragging = true"
                @dragend="dragging = false"
              >
                <l-tooltip
                  :content="tooltipContentDepart"
                  :options="{ permanent: true }"
                />

              </l-marker>
            </l-map>
          </b-card>
        </b-modal>
        <b-modal
          id="localisation-destination"
          ref="my-modal-destination"
          ok-title="Fermer"
          ok-only
          button-size="sm"
          centered
          modal-class="modal-primary"
          size="lg"
          no-close-on-backdrop
          title="Ajouter une géolocalisation destination"
          @show="showModal('destination')"
        >
          <b-card
            title="Géolocalisation"
            style="height: 39rem !important;"
          >
            <l-map
              ref="mapDestination"
              :zoom="zoom"
              :center="userLocationDestination"
              :options="mapOptions"
              style="width:100%; height: 445px"
              @click="onMapClickDestination"
              @ready="doSomethingOnReadyDestination()"
            >
              <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"
              />
              <l-circle
                :lat-lng.sync="userLocationDestination"
                :radius="circle.radius"
                :color="circle.color"
              />
              <l-control-attribution
                :position="attributionPosition"
                :prefix="attributionPrefix"
              />
              <l-geosearch :options="geoSearchOptions" />

              <l-marker
                :lat-lng.sync="userLocationDestination"
                visible
                :icon="iconT"
                draggable
                @dragstart="dragging = true"
                @dragend="dragging = false"
              >
                <l-tooltip
                  :content="tooltipContentDestination"
                  :options="{ permanent: true }"
                />

              </l-marker>
            </l-map>
          </b-card>
        </b-modal>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  LMap, LTileLayer, LMarker, LControlAttribution, LCircle, LTooltip,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import {
  ref, reactive, onMounted, watch, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password,
} from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions, import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr.js'

import {
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BContainer,
  BCard,
  BInputGroup,
  BButton,
  BModal, VBModal,
  BFormCheckbox,
  BFormFile,
  BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import useCountries from '@/services/admin/countryService'
// eslint-disable-next-line import/no-cycle
import useDepartements from '@/services/admin/departementService'
// eslint-disable-next-line import/no-cycle
import useCategoryMarchandises from '@/services/admin/categoryMarchandiseService'
// eslint-disable-next-line import/no-cycle
import useMesures from '@/services/admin/uniteDeMesureService'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
import { Icon, icon } from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'
// eslint-disable-next-line import/no-cycle
import useTrackings from '@/services/tracking/trackingService'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BButton,
    BInputGroup,
    BFormCheckbox,
    BModal,
    BFormFile,
    flatPickr,
    BOverlay,
    vSelect,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    LMap,
    LTileLayer,
    LControlAttribution,
    LCircle,
    LGeosearch,
    LMarker,
    LTooltip,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      email,
      confirmed,
      password,
    }
  },
  setup() {
    const {
      getCountries, countries, getCountry, country,
    } = useCountries()
    const { getDepartement, departement } = useDepartements()
    const {
      getCategoryMarchandises, categoryMarchandises, getCategoryMarchandise, categoryMarchandise,
    } = useCategoryMarchandises()
    const { getMesures, mesures } = useMesures()

    const { createOffre, offreProcess } = useOffres()
    const {
      getUserPosition,
    } = useTrackings()
    const {
      imageUrl,
      imageFile,
      handleImageSelected,
      uploadFile,
      fileName,
      // deleteFile,
    } = useImageUpload()

    const fields = reactive([
      { key: 'type_document', label: 'Type de document' },
      { key: 'numero_de_la_piece', label: 'Numéro de la pièce' },
      { key: 'date_expiration', label: "Date d'expiration" },
      { key: 'actions' },
    ])
    const items = reactive([])
    const image = ref({})

    const upload = async () => {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadFile(data)
      image.value = fileName.value
    }
    const zoom = ref(16)
    const loading = ref(false)
    const addressDepart = ref('')
    const addressDestination = ref('')
    const dragging = ref(false)
    const attributionPrefix = ref('eBdfret')
    const attributionPosition = ref('bottomright')
    const defaultLocation = ref({
      lat: null,
      lng: null,
    })
    const userLocationDepart = ref({
      lat: null,
      lng: null,
    })
    const userLocationDestination = ref({
      lat: null,
      lng: null,
    })
    const getAddress = async type => {
      loading.value = true
      if (type === 'depart') addressDepart.value = 'Unresolved address'
      else addressDestination.value = 'Unresolved address'
      try {
        const { lat, lng } = type === 'depart' ? userLocationDepart.value : userLocationDestination.value
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`,
        )
        if (result.status === 200) {
          const body = await result.json()
          if (type === 'depart') addressDepart.value = body.display_name
          else addressDestination.value = body.display_name
        }
      } catch (e) {
        console.error('Reverse Geocode Error->', e)
      }
      loading.value = false
      if (type === 'depart') return addressDepart.value
      return addressDestination.value
    }

    const onMapClickDepart = async value => {
      // place the marker on the clicked spot
      userLocationDepart.value = value.latlng
    }
    const onMapClickDestination = async value => {
      // place the marker on the clicked spot
      userLocationDestination.value = value.latlng
    }
    const mapOptions = reactive({
      zoomControl: true,
      attributionControl: false,
      zoomSnap: true,
    })
    const geoSearchOptions = reactive({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      autoClose: true,
    })
    const circle = reactive({
      radius: 90,
      color: '#EA5455',
    })
    const tileProviders = ref([
      {
        name: 'OpenStreetMap',
        visible: true,
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
    ])
    const iconT = icon({
      iconUrl: require('@/assets/images/misc/location.gif'),
      iconSize: [50, 50],
      iconAnchor: [16, 37],
    })
    const updatePosition = async () => {
      const position = await getUserPosition()
      const { coords } = position
      defaultLocation.value.lat = coords.latitude
      defaultLocation.value.lng = coords.longitude
      // console.log(userLocationDepart)
    }

    const tooltipContentDepart = computed(() => {
      if (dragging.value) return '...'
      if (loading.value) return 'Loading...'
      return `<strong>${addressDepart.value.replace(
        ',',
        '<br/>',
      )}</strong> <hr/><strong>lat:</strong> ${userLocationDepart.value.lat
      }<br/> <strong>lng:</strong> ${userLocationDepart.value.lng}`
    })
    const tooltipContentDestination = computed(() => {
      if (dragging.value) return '...'
      if (loading.value) return 'Loading...'
      return `<strong>${addressDestination.value.replace(
        ',',
        '<br/>',
      )}</strong> <hr/><strong>lat:</strong> ${userLocationDestination.value.lat
      }<br/> <strong>lng:</strong> ${userLocationDestination.value.lng}`
    })

    onMounted(async () => {
      // await updatePosition()

      await getCountries()
      await getCategoryMarchandises()
      await getMesures()
      setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 250)
    })

    const form = reactive({
      ville_depart_id: '',
      country_depart: '',
      departement_depart: '',

      country_arrive: '',
      ville_destination_id: '',
      departement_arrive: '',

      categorie_marchandise_slug: '',
      marchandise_slug: '',
      unite_mesure_id: '',

      poids_marchandise: '',
      image: '',
      volume: '',
      longueur: '',
      longitudeDepart: null,
      latitudeDepart: null,
      addressDepart: null,
      longitudeDestination: null,
      latitudeDestination: null,
      addressDestination: null,

      date_prev_depart: '',
      date_prev_livraison: '',

      nom_contact: '',
      email_contact: '',
      telephone_contact: '',
      telephonefull: '',
      diffusion: null,
      commentaire: '',

    })
    const showModal = async type => {
      await updatePosition()

      setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 250)
      if (type === 'depart') {
        userLocationDepart.value.lat = form.latitudeDepart === null ? defaultLocation.value.lat : form.latitudeDepart
        userLocationDepart.value.lng = form.longitudeDepart === null ? defaultLocation.value.lng : form.longitudeDepart
      } else {
        userLocationDestination.value.lat = form.latitudeDestination === null ? defaultLocation.value.lat : form.latitudeDestination
        userLocationDestination.value.lng = form.longitudeDestination === null ? defaultLocation.value.lng : form.longitudeDestination
      }
    }
    const clearLocation = type => {
      if (type === 'depart') {
        form.latitudeDepart = null
        form.longitudeDepart = null
        form.addressDepart = null
      } else {
        form.latitudeDestination = null
        form.longitudeDestination = null
        form.addressDestination = null
      }
    }
    const onSearchDepart = async value => {
      const loc = value.location
      form.longitudeDepart = loc.x
      form.latitudeDepart = loc.y
      userLocationDepart.value = { lat: loc.y, lng: loc.x }
    }
    const onSearchDestination = async value => {
      const loc = value.location
      form.longitudeDestination = loc.x
      form.latitudeDestination = loc.y
      userLocationDestination.value = { lat: loc.y, lng: loc.x }
    }
    const telephonefull = ref('')
    watch(() => (form.telephonefull), () => {
      form.telephone_contact = ''
      form.telephone_contact = telephonefull.value.formattedNumber
      console.clear()
    })

    // S'Enregistrer
    const storeOffre = async () => {
      await upload()
      form.image = image.value
      await createOffre({ ...form })
      console.clear()
    }
    const currentDepartements = ref([])
    const currentCountry = async data => {
      console.clear()
      country.value = ''
      await getCountry(data)
      currentDepartements.value = country.value.departements
    }

    const currentVilles = ref([])

    const currentDepartement = async data => {
      console.clear()
      departement.value = ''
      await getDepartement(data)
      currentVilles.value = departement.value.villes
    }

    const currentType = ref([])

    const currentMarchandise = async data => {
      console.clear()
      await getCategoryMarchandise(data)
      currentType.value = categoryMarchandise.value.marchandises
    }

    const currentVillesArrive = ref([])

    const currentDepartementArrive = async data => {
      console.clear()
      departement.value = ''

      await getDepartement(data)
      currentVillesArrive.value = departement.value.villes
    }

    watch(() => _.cloneDeep(form.country_depart), value => {
      currentCountry(value)
    })
    watch(() => _.cloneDeep(form.departement_depart), value => {
      currentDepartement(value)
    })
    watch(() => _.cloneDeep(form.country_arrive), value => {
      currentCountry(value)
    })
    watch(() => _.cloneDeep(form.departement_arrive), value => {
      currentDepartementArrive(value)
    })
    watch(() => _.cloneDeep(form.categorie_marchandise_slug), value => {
      currentMarchandise(value)
    })

    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y H i',
      altInput: true,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      locale: French, // locale for this instance only
    }

    return {
      config,
      countries,
      currentDepartements,
      currentVilles,
      currentVillesArrive,
      categoryMarchandises,
      mesures,
      currentType,
      form,
      items,
      fields,
      storeOffre,
      imageUrl,
      handleImageSelected,
      telephonefull,
      offreProcess,
      zoom,
      iconT,
      onMapClickDepart,
      geoSearchOptions,
      attributionPrefix,
      attributionPosition,
      tooltipContentDepart,
      userLocationDestination,
      mapOptions,
      userLocationDepart,
      circle,
      addressDepart,
      addressDestination,
      tileProviders,
      showModal,
      clearLocation,
      onSearchDepart,
      dragging,
      getAddress,
      tooltipContentDestination,
      onSearchDestination,
      onMapClickDestination,

    }
  },
  watch: {
    userLocationDepart: {
      deep: true,
      async handler(value) {
        this.addressDepart = await this.getAddress('depart')
        this.form.addressDepart = this.addressDepart
        console.log('Depart')
        const { lat, lng } = value
        this.form.longitudeDepart = lng
        this.form.latitudeDepart = lat
        this.$emit('input', { userLocationDepart: value, addressDepart: this.addressDepart })
      },
    },
    userLocationDestination: {
      deep: true,
      async handler(value) {
        this.addressDestination = await this.getAddress('destination')
        this.form.addressDestination = this.addressDestination
        console.log(this.form.addressDestination)
        const { lat, lng } = value
        this.form.longitudeDestination = lng
        this.form.latitudeDestination = lat
        this.$emit('input', { userLocationDestination: value, addressDestination: this.addressDestination })
      },
    },
  },
  methods: {
    doSomethingOnReady() {
      this.$refs.map.mapObject.on('geosearch/showlocation', this.onSearchDepart)
    },
    doSomethingOnReadyDestination() {
      this.$refs.mapDestination.mapObject.on('geosearch/showlocation', this.onSearchDestination)
    },

    formatter(value) {
      return value.toLowerCase()
    },

    validationMarchandise() {
      return new Promise((resolve, reject) => {
        this.$refs.marchandiseRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationIsConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountConditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.conditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    ValidationTrajetForm() {
      return new Promise((resolve, reject) => {
        this.$refs.trajetRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationContactForm() {
      return new Promise((resolve, reject) => {
        this.$refs.contactRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
