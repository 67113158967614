import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'

export default function useCategoryMarchandises() {
  const { errorToast, successToast } = useAlertNotification()
  const categoryMarchandise = ref([])
  const loader = ref(false)
  const categoryMarchandiseProcess = ref(false)
  const categoryMarchandiseSuccess = ref(false)
  const categoryMarchandises = ref([])
  const errors = ref('')

  // Liste des categoryMarchandises
  const getCategoryMarchandises = async () => {
    loader.value = true
    await axiosClient.get('/categorie_marchandises').then(response => {
      if (response.data.success === true) {
        loader.value = false
        categoryMarchandises.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une categoryMarchandise
  const getCategoryMarchandise = async slug => {
    const response = await axiosClient.get(`/categorie_marchandises/${slug}`)
    categoryMarchandise.value = response.data.data
  }
  // Ajouter une categoryMarchandise
  const createCategoryMarchandise = async data => {
    errors.value = ''
    categoryMarchandiseProcess.value = true
    await axiosClient.post('/categorie_marchandises', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          categoryMarchandiseSuccess.value = true
          categoryMarchandiseProcess.value = false
          getCategoryMarchandises()
        }
      })
      .catch(error => {
        categoryMarchandiseProcess.value = false
        categoryMarchandiseSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une categoryMarchandise
  const updateCategoryMarchandise = async data => {
    errors.value = ''
    categoryMarchandiseProcess.value = true
    await axiosClient.patch(`/categorie_marchandises/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          categoryMarchandiseSuccess.value = true
          categoryMarchandiseProcess.value = false
          getCategoryMarchandises()
        }
      })
      .catch(error => {
        categoryMarchandiseProcess.value = false
        categoryMarchandiseSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    categoryMarchandise,
    categoryMarchandises,
    getCategoryMarchandises,
    categoryMarchandiseProcess,
    createCategoryMarchandise,
    updateCategoryMarchandise,
    getCategoryMarchandise,
    loader,
    categoryMarchandiseSuccess,
  }
}
